* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
body,html,#root {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: Voyage;
  src: url('/Voyage-Regular.eot'); /* IE9 Compat Modes */
  src: url('/Voyage-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/Voyage-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/Voyage-Regular.woff') format('woff')
}

@font-face {
  font-family: Voyage-Regular;
  src: url('/Voyage-Regular.eot'); /* IE9 Compat Modes */
  src: url('/Voyage-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/Voyage-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/Voyage-Regular.woff') format('woff')
}
